/*
|---------------------------------------------------------------------
| Vue App
|---------------------------------------------------------------------
|
| This is the main vue app instance into which components are loaded.
|
*/

/**
 * Set the CSRF token on all HTTP requests
 */
Vue.http.headers.common['X-CSRF-TOKEN'] = ProduceLogic.csrfToken;

/**
 * Create the main vue instance
 */
new Vue({
    el: '#vue-app',
});

/*
|---------------------------------------------------------------------
| Basic jQuery Stuff
|---------------------------------------------------------------------
|
| Use this section to setup basic jQuery plugins and other global
| config related stuff.
|
*/

$(document).ready(function () {

    /**
     * Initialize any datepickers on the page
     */
    $('.datepicker').datepicker({
        autoclose: true,
        disableTouchKeyboard: true,
        todayHighlight: true,
        todayBtn: 'linked',
    }).on('show', function (e) {
        if ($(this).val() == '') {
            $(this).datepicker('update', new Date());
        }
    });

    /**
     * Initialize fastclick for 'snappier' mobile experience
     */
    FastClick.attach(document.body);

});
