/*
|--------------------------------------------------------------------------
| Sales Ticket Component
|--------------------------------------------------------------------------
|
| This component mimics the Enter Sales Ticket / Lot Selector forms in the
| Order Entry section of ProduceLogic.
|
*/

Vue.component('sales-ticket', {

    data: function() {           
        if (ProduceLogic.hasOwnProperty('order')) {
            return this.loadTicket();
        }

        return this.defaults();
    },

    ready: function() {
        this.fetchCustomers();
        this.guardAgainstDoNotSellCustomer();
    },

    computed: {
        /*
         * Calculates the total number of items in the order.
         *
         * @return {number}
         */
        quantity: function() {
            var quantity = 0;

            this.ticket.Materials.forEach(function(material) {
                quantity += Number(material.Quantity);
            });

            return quantity;
        },

        /*
         * Calculates the order total amount.
         *
         * @return {number}
         */
        amount: function() {
            var total = 0;

            this.ticket.Materials.forEach(function(material) {
                total += (Number(material.Price) * Number(material.Quantity));
            });

            return total;
        },

        /**
         * Returns the materials availabe for display in the lot selector. 
         * This may be the detail list or the summary list.
         *
         * @return {array}
         */
        lotSelector: function() {
            return this.lotSelectorView === 'detail' ? this.lotDetail : this.lotSummary;
        },

        /**
         * Sets the currently selected customer.
         *
         * @return {object}
         */
        selectedCustomer: function() {
            var self = this;

            var filtered = this.customers.filter(function(customer) {
                return customer.CustomerID === self.ticket.CustomerID;
            });

            var selectedCustomer = filtered[0] || {};

            this.checkDoNotSell(selectedCustomer);

            // Alert the child toggle button component of changes to the 
            // isToggled value
            var isBroker = selectedCustomer.BrokerID != null;

            this.$broadcast('is-toggled-changed', isBroker);

            return selectedCustomer;
        }
    },

    methods: {
        /**
         * Fetches customers from the server.
         */
        fetchCustomers: function() {
            this.$http.get('/api/customers')
                .success(function(response) {
                    this.customers = response;
                })
                .error(function(response) {
                    swal('Error', 'Unable to load customer data', 'error');
                });
        },

        /**
         * Fetches lots from the server.
         */
        fetchLots: function() {
            if (!this.searchFields.materialDescription && !this.searchFields.materialNumber) {
                swal('Warning', 'Please enter an item description or inventory code', 'warning');
                return;
            }

            this.loading = true;

            var url = '/api/lots?material_description=' +
                (this.searchFields.materialDescription || '') +
                '&material_number=' +
                (this.searchFields.materialNumber || '');

            this.$http.get(url)
                .success(function(lots) {
                    this.lotSummary = lots.summary;
                    this.lotDetail = lots.detail;
                    this.searchFields = {materialDescription: '', materialNumber: ''};
                    this.loading = false;
                    $('#lot-selector-modal').modal('show');

                    // We need to add the Quantity property to the model so 
                    // that vue can track it properly.
                    this.lotSummary.forEach(function (lot) {
                        lot.$set('Quantity', '');
                    });

                    // We need to add the Quantity property to the model so 
                    // that vue can track it properly.
                    this.lotDetail.forEach(function (lot) {
                        lot.$set('Quantity', '');
                    });
                })
                .error(function(response) {
                    swal('Error', ProduceLogic.firstError(response.errors || response), 'error');
                    this.loading = false;
                });
        },

        /**
         * Adds selected items in the lot selector to the sales ticket.
         */
        addItems: function() {
            var self = this;

            // Use this logic if the summary view was used to pick items.
            if (this.lotSelectorView === 'summary') {
                this.lotSelector.filter(function(material) {
                    return material.Quantity > 0;
                }).forEach(function(material) {
                    self.addFromLot(material);
                });
            }

            // Use this logic if the detail view was used to pick items.
            else if (this.lotSelectorView === 'detail') {
                this.lotSelector.forEach(function(material) {
                    if (material.Quantity > 0) {
                        self.ticket.Materials.push(material);
                    }
                });
            }

            // If the authenticated user is NOT an employee, we need to zero 
            // out the price so that the order can be priced in ProduceLogic
            if (!ProduceLogic.user.IsEmployee) {
                this.ticket.Materials.forEach(function(material) {
                    material.Price = 0;
                });
            }

            // Add properties that the server is expecting. These aren't 
            // included in the server response when we search for lots
            // for some reason.
            this.ticket.Materials.map(function(material) {
                material.BackOrdered = material.Quantity;
                material.UnitFreight = 0;
            });

            $('#lot-selector-modal').modal('hide');
        },

        /**
         * Adds the given item to the ticket by pulling it from suitable lots.
         *
         * @param {object} material
         */
        addFromLot: function(material) {
            var self = this;

            // Get the lots suitable for the given material sorted oldest to 
            // newest. The order is important as we use older lots first.
            var suitableLots = this.lotDetail.filter(function(lot) {
                return self.lotIsSuitable(material, lot);
            }).sort(function(a, b) {
                return a.LotNumber > b.LotNumber;
            });

            // Iterate through suitable lots and add line items to the ticket.
            var qtyToAdd = material.Quantity;

            for (var i = 0; i < suitableLots.length; i++) {

                // We have satisfied the quantity. Break out of the loop early.
                if (qtyToAdd <= 0) {
                    break;
                }

                // Save the suitable lot to a local var for convenience.
                var lot = suitableLots[i];

                // If the lot has enough, take what we need.
                if (Number(lot.Available) >= qtyToAdd) {
                    lot.Quantity = qtyToAdd;
                    qtyToAdd = 0;
                }

                // If the lot does not have enough, take what we can.
                if (Number(lot.Available) < qtyToAdd) {
                    lot.Quantity = lot.Available;
                    qtyToAdd -= lot.Available;

                    // If this is the last suitable lot, oversell it.
                    if (i + 1 == suitableLots.length) {
                        lot.Quantity = lot.Available + qtyToAdd;
                        qtyToAdd = 0;
                    }
                }

                // If an employee is logged in and changed any details, make 
                // sure we copy them over here.
                if (ProduceLogic.user.IsEmployee) {
                    lot.Price = material.Price;
                }

                // Add the lot (now with details) to the ticket.
                self.ticket.Materials.push(lot);
            }
        },

        /**
         * Determine if the given lot is appropriate for us to use for the 
         * given material.
         *
         * @param {object} material
         * @param {object} lot
         *
         * @return {boolean}
         */
        lotIsSuitable: function(material, lot) {
            return Number(lot.Available) > 0 &&
                material.MaterialID === lot.MaterialID &&
                material.MaterialNumber === lot.MaterialNumber &&
                material.MaterialDescription === lot.MaterialDescription &&
                material.Pack === lot.Pack &&
                material.Label === lot.Label &&
                material.LocationNumber === lot.LocationNumber &&
                material.FractionalQtys === lot.FractionalQtys;
        },

        /**
         * Removes an item from the sales ticket.
         *
         * @param {number} index
         */
        removeItem: function(index) {
            var self = this;

            swal({
                title: 'Confirm',
                text: 'Remove this item from the ticket?',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }, function() {
                self.ticket.Materials.splice(index, 1);
            });
        },

        /**
         * Removes an item from the sales ticket when editing an existing 
         * ticket. When editing, we pass all items (including those we
         * want to remove) to a stored procedure that handles the
         * updates for us.
         *
         * @param {Number} index
         * @param {Object} item
         *
         * @return {Void}
         */
        markItemForRemoval: function (index, item) {
            var self = this;

            console.log(this.ticket.Materials.length );

            if (this.ticket.Materials.length == 1) {
                swal('Error', 'There must be at least one item on this ticket', 'error');
                return;
            }

            swal({
                title: 'Confirm',
                text: 'Remove this item from the ticket?',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }, function() {
                item.DeleteLine = true;
                self.ticket.Materials.splice(index, 1);
                self.ticket.DeletedMaterials.push(item);
            });
        },

        /**
         * Add a recipient to the text/email notification that is sent when an 
         * employee submits an order.
         */
        addRecipient: function() {
            if (!this.recipient) {
                swal('Error', 'The recipient cell is required', 'error');
                return;
            }

            this.recipients.push(this.recipient + '@vtext.com');
            this.recipient = '';
        },

        /**
         * Remove a recipient from the text/email notification list.
         *
         * @param {Number} index
         *
         * @return {void}
         */
        removeRecipient: function(index) {
            this.recipients.splice(index, 1);
        },

        /**
         * Add a suggested recipient to the recipients list.
         *
         * @param {String} recipient
         *
         * @return {void}
         */
        enableRecipient: function(recipient) {
            this.recipients.push(recipient + '@vtext.com');
        },

        /*
         * Warns the user that the quantity requested is greater than the 
         * amount available. Adjusts the quantity down for users who are
         * not employees.
         *
         * @param {object} material
         */
        guardAgainstOversell: function(material) {
            if (Number(material.Quantity) > Number(material.Available)) {
                var message = 'The selected quantity is greater than the available quantity for this item';

                if (!ProduceLogic.user.IsEmployee) {
                    message += '. The quantity will be automatically adjusted.';
                }

                swal({
                    title: 'Warning',
                    text: message,
                    type: 'warning'
                }, function() {
                    if (!ProduceLogic.user.IsEmployee) {
                        material.Quantity = Number(material.Available);
                    }
                });
            }
        },

        /**
         * Prevent users whose customer is on the do not sell list from 
         * creating an order.
         */
        guardAgainstDoNotSellCustomer: function() {
            if (ProduceLogic.user.customer.DoNotSell > 0) {
                swal({
                    title: 'Warning',
                    text: 'There is currently an issue with your customer account. Please call a TMK representative for assistance.',
                    type: 'warning'
                });
            }
        },

        /**
         * Check the customer to see if they are flagged for do not sell.
         *
         * @param {object} selectedCustomer
         */
        checkDoNotSell: function(selectedCustomer) {
            if (!selectedCustomer.hasOwnProperty('DoNotSell')) {
                return;
            }

            if (selectedCustomer.DoNotSell == 0) {
                return;
            }

            var self = this;

            var title = selectedCustomer.DoNotSell == 1 ? 'Credit Stop Override' : 'Credit Hold Override';
            var text = selectedCustomer.do_not_sell_text.DNSText;
            var type = selectedCustomer.DoNotSell == 1 ? 'warning' : 'input';
            var showCancel = selectedCustomer.DoNotSell != 1;
            var closeOnConfirm = selectedCustomer.DoNotSell == 1;
            var showLoaderOnConfirm = selectedCustomer.DoNotSell != 1;

            swal({
                title: title,
                text: text,
                type: type,
                showCancelButton: showCancel,
                inputPlaceholder: 'Enter your password',
                inputType: 'password',
                showLoaderOnConfirm: showLoaderOnConfirm,
                closeOnConfirm: closeOnConfirm
            }, function(inputValue) {
                // User did not enter password. Deselect the customer and return early.
                if (typeof inputValue !== 'string') {
                    self.ticket.CustomerID = ProduceLogic.user.CustomerID;
                    return;
                }

                // Use entered password. Verify it.
                self.$http.post('/api/users/verify-password', { password: inputValue })
                    .success(function(response) {
                        if (response.verified) {
                            swal('Success', 'You may continue with your order', 'success');
                            self.ticket.DNSApprovalID = ProduceLogic.webUserEmployeeId;
                        } else {
                            swal('Error', 'Incorrect password', 'error');
                            self.ticket.CustomerID = ProduceLogic.user.CustomerID;
                        }
                    })
                    .error(function(response) {
                        swal('Error', 'Unable to verify your password at this time', 'error');
                        self.ticket.CustomerID = ProduceLogic.user.CustomerID;
                    });
            });
        },

        /**
         * Make sure the user didn't accidentally skip the notification 
         * section and save the order to storage.
         *
         * @return {Void}
         */
        save: function() {
            var self = this;

            if (ProduceLogic.user.IsEmployee && !this.recipients.length) {
                swal({
                    title: 'Confirm',
                    text: 'Continue without sending notifications?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes'
                }, function(confirmed) {
                    if (confirmed) {
                        self.performSave();
                    }
                });
            } else {
                this.performSave();
            }
        },

        /**
         * Save the order to storage.
         *
         * @return {Void}
         */
        performSave: function() {
            var self = this;
            this.saving = true;
            this.ticket.Amount = this.amount;

            this.$http.post('/api/orders', {ticket: this.ticket, recipients: this.recipients})
                .success(function(response) {
                    swal({
                        title: 'Success', text: 'Your ticket has been created', type: 'success'
                    }, function () {
                        window.location = '/orders/' + response.order.OrderID;
                    });
                })
                .error(function(response) {
                    swal('Error', ProduceLogic.firstError(response.errors || response), 'error');
                    this.saving = false;
                });
        },

        /**
         * Update the order in storage.
         *
         * @return {Void}
         */
        update: function () {
            this.ticket.Amount = this.amount;

            this.$http.put('/api/orders/' + this.ticket.OrderID, {ticket: this.ticket})
                .success(function (response) {
                    swal('Success', response.message, 'success');
                })
                .error(function (response) {
                    swal('Error', ProduceLogic.firstError(response.errors || response), 'error');
                });
        },

        /**
         * Returns default data values for the vue instance.
         *
         * @return {object}
         */
        defaults: function() {
            return {
                lotDetail: [],
                lotSummary: [],
                lotSelectorView: 'summary',
                customers: [],
                searchFields: {
                    materialDescription: '',
                    materialNumber: ''
                },
                loading: false,
                saving: false,
                ticket: {
                    Amount: 0,
                    Broker: ProduceLogic.user.customer.Broker,
                    CustomerID: ProduceLogic.user.CustomerID,
                    Materials: [],
                    DeletedMaterials: [],
                    Notes: '',
                    OrderDate: '',
                    OrderID: '',
                    PO: '',
                    Route: '',
                    DNSApprovalID: 0
                },
                recipients: [],
                recipient: ''
            }
        },

        /**
         * Populates data values from an existing order.
         *
         * @return {object}
         */
        loadTicket: function() {
            var data = this.defaults();

            // Unfortunately the model names and front end input names don't 
            // match up perfectly, so we will transform the data from the 
            // server so that it loads into our UI properly. This would
            // ideally be updated and cleaned up at some point.
            ProduceLogic.order.order_details.forEach(function (orderDetail) {
                orderDetail.Quantity = orderDetail.Qty;
                orderDetail.MaterialDescription = orderDetail.Description;
                orderDetail.Price = orderDetail.UnitPrice;
                orderDetail.LotNumber = orderDetail.material_batch.LotNumber;
            });

            data.ticket = {
                Amount: ProduceLogic.order.Amount,
                Broker: ProduceLogic.order.Broker,
                CustomerID: ProduceLogic.order.CustomerID,
                Materials: ProduceLogic.order.order_details,
                DeletedMaterials: [],
                Notes: ProduceLogic.order.Notes,
                OrderDate: ProduceLogic.order.OrderDate,
                OrderID: ProduceLogic.order.OrderID,
                PO: ProduceLogic.order['PO#'],
                Route: ProduceLogic.order.RouteID,
                DNSApprovalID: ProduceLogic.order.DNSApprovalID
            };

            return data;
        },

        /**
         * Resets the vue instance data values.
         */
        reset: function() {
            this.$data = this.defaults();
        }
    }
});
