/**
 * Transforms the given value into an integer.
 *
 * @param {number} value
 * 
 * @return {number}
 */
Vue.filter('integer', {
    read: function (value) {
        return Math.round(value);
    },

    write: function (value) {
        return value;
    }
});

/**
 * Creates an address block for the given customer.
 *
 * @param {object} customer
 * 
 * @return {string}
 */
Vue.filter('customerAddressBlock', {
    read: function (customer) {
        // Return early if the customer is undefined
        if (!customer) {
            return;
        }

        // Format the customer name and street address lines
        var part1 = [
            customer.CustomerName,
            customer.ShipToAddress,
            customer.ShipToAddress2
        ].filter(function (part) {
            return part;
        }).join('<br>');

        // Format the city, state and zip line
        var part2 = [
            (customer.ShipToCity && customer.ShipToState) ? customer.ShipToCity + ', ' : customer.ShipToCity,
            customer.ShipToState,
            customer.ShipToZip
        ].filter(function (part) {
            return part;
        }).join(' ');

        // Filter out any emptys one last time
        var parts = [part1, part2].filter(function (part) {
            return part;
        });

        // Return the final formatted string
        return parts.join('<br>');
    }
});

/**
 * Formats a phone number.
 * Example: 610-401-4565 => (610) 401-4565
 *
 * @param {String} phone
 * @return {Void}
 */
Vue.filter('phone', function (phone) {
    return phone.replace(/[^0-9]/g, '')
                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});
